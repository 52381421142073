import { Component } from 'vue-property-decorator'

import { SET_META } from 'booksprout-app'

import BaseLayoutMixin from '../mixins/baseLayoutMixin'
import { WebsitePaths } from '../../../router/path.constants'

import PrivacyPolicyContent from 'booksprout-app/src/components/PrivacyPolicyContent.vue'

@Component({
  components: {
    PrivacyPolicyContent
  }
})
export default class PrivacyPolicy extends BaseLayoutMixin {
  websitePaths = { ...WebsitePaths }

  created () {
    void this.$store.dispatch(SET_META, {
      title: 'Privacy Policy',
      canonicalUrl: window.location.origin + this.websitePaths.privacyPolicy
    })
  }
}
